export const CssLogo = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="75"
    viewBox="0 0 200 75"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M.058 0h58.667v24.533H24.59v24.534h34.134V73.6H.058V0ZM70.458 0h58.667v21.333H94.991V25.6h34.134v49.067H70.458v-22.4h34.133V48H70.458V0ZM140.858 0h58.667v21.333h-34.134V25.6h34.134v49.067h-58.667v-22.4h34.133V48h-34.133V0Z"
    />
  </svg>
);
