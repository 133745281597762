import { ArrowRight, Code } from "lucide-react";
import { FramerLogo } from "@fefyi/ui/astro/icons/framer-logo";
import { CssLogo } from "@fefyi/ui/astro/icons/css-logo";
import { Tile } from "./tile";
import { TailwindLogo } from "~/content/recipes/components-and-css/css-only-logo-marquee/_logos";

export const SubnavTuts = () => (
  <>
    <div className="grid gap-2 md:grid-cols-[repeat(2,250px)] lg:grid-cols-[repeat(2,250px)]">
      <Tile
        title="Framer Motion"
        subtitle="Smooth smooth animations"
        icon={FramerLogo}
        href="/videos#framer-motion"
      />
      <Tile
        title="Rebuilds"
        subtitle="I can build that too!"
        icon={Code}
        href="/videos/#rebuilds"
      />
      <Tile
        title="CSS Features"
        subtitle="Modern CSS is so cool"
        icon={CssLogo}
        href="/videos/#css"
      />
      <Tile
        title="TailwindCSS"
        subtitle="You'll learn some CSS here too ;)"
        icon={TailwindLogo}
        href="/videos/#tailwind"
      />
      <Tile
        icon={ArrowRight}
        className="mr-auto border border-white-opaque bg-white-opaque px-4 md:hidden [&_svg]:w-4"
        title="View all"
        href="/videos"
      />
    </div>
  </>
);
